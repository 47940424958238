import { DateTime } from 'luxon';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

const TimeDisplay = () => {
  const [timeData, setTimeData] = useState(DateTime.now());

  useInterval(() => {
    setTimeData(DateTime.now());
  }, 1000);

  return (
    <div className="border border-red-600 bg-[#ffefef] py-5 text-center font-bold">
      <div className="text-xl">{timeData.setLocale('ja').toFormat('yyyy年M月d日（ccc）')}</div>
      <div className="pt-4 text-2xl">{timeData.setLocale('ja').toFormat('HH時m分')}</div>
    </div>
  );
};

export default TimeDisplay;
