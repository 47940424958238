import LoginForm, { LoginFormProps } from '../organisms/login-form';
import LoginHeader from '../organisms/login-header';

type LoginProps = LoginFormProps & {
  isTenant?: boolean;
};

const Login = ({ idText, passText, onSubmit, buttonText, isTenant = false }: LoginProps) => {
  return (
    <LoginHeader isTenant={isTenant}>
      <LoginForm idText={idText} passText={passText} onSubmit={onSubmit} buttonText={buttonText} />
    </LoginHeader>
  );
};

export default Login;
