import { SubmitHandler } from 'react-hook-form';
import { fetchUrl } from 'src/constant/constant';
import { useAuthContext } from 'src/context/context';
import { UserLoginReq } from 'src/data-type/request-data-type';
import { UserLoginRes } from 'src/data-type/response-data-type';
import { InfoMessage } from 'src/enum/message-enum';
import appFetch from 'src/services/app-fetch';
import { FormInputs } from '../organisms/login-form';
import Login from '../templates/login';

const UserLogin = () => {
  const { setAuthState } = useAuthContext();

  const onUserSubmit: SubmitHandler<FormInputs> = async (data) => {
    const res = await appFetch.post<UserLoginReq, UserLoginRes>(fetchUrl.authUser, {
      userId: Number(data.id),
      password: data.password,
    });

    if (res === undefined) return;

    if (res.status === 200) {
      if (res.data.isUserFound) {
        setAuthState({ isTenantLogin: true, isUserLogin: true });
      } else {
        window.alert(InfoMessage.UserNotFound);
      }
    } else if (res.status === 401) {
      // テナント認証情報エラーなので、テナントログイン画面に引き戻す
      setAuthState({ isTenantLogin: false, isUserLogin: false });
    }
  };

  return <Login idText="ログインID" passText="パスワード" onSubmit={onUserSubmit} buttonText="ログイン" />;
};

export default UserLogin;
