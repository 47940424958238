import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { fetchUrl } from 'src/constant/constant';
import { Koji } from 'src/data-type/data-type';
import { DakokuRegisterReq, ListDakokuReq } from 'src/data-type/request-data-type';
import { ListDakokuRes } from 'src/data-type/response-data-type';
import { InfoMessage } from 'src/enum/message-enum';
import appFetch from 'src/services/app-fetch';
import { useUserInfo } from 'src/services/hooks';
import utils from 'src/services/utils';
import DataRow from '../atoms/data-row';
import IconTextButton from '../molecules/icon-text-button';

type DakokuProps = {
  koji: Koji | undefined;
};

const Dakoku = ({ koji }: DakokuProps) => {
  const userInfo = useUserInfo();
  const [listDakoku, setListDakoku] = useState<ListDakokuRes[]>([]);
  const currentDate = useMemo(() => DateTime.now().toFormat('MM/dd'), [DateTime.now().toFormat('MM/dd')]);

  useEffect(() => {
    searchDakoku();
  }, []);

  const searchDakoku = async () => {
    const res = await appFetch.post<ListDakokuReq, ListDakokuRes[]>(fetchUrl.listDakoku, {
      dakokuDate: DateTime.now().setLocale('ja').toFormat("yyyy-MM-dd'T'00:00:00.000ZZ"),
    });
    if (res === undefined) return;

    if (res.status === 200) {
      setListDakoku(res.data);
    }
  };

  const registerDakoku = async (dakokuKb: number, latitude: number, longitude: number) => {
    // このFunctionが呼ばれるタイミングではkojiはundefinedではないので、明示的にTypeを指定する
    const dakoku = await appFetch.post<DakokuRegisterReq>(fetchUrl.registerDakoku, {
      parentCode: (koji as Koji).parentCode,
      childCode: (koji as Koji).childCode,
      name: (koji as Koji).shortName,
      dakokuType: dakokuKb,
      latitude: latitude,
      longitude: longitude,
    });

    if (dakoku === undefined) return;

    if (dakoku.status === 201) {
      searchDakoku();
    } else if (dakoku.status === 200) {
      window.alert(InfoMessage.DakokuDuplication);
    }
  };

  const onClick = (dakokuKb: number) => {
    if (koji === undefined) return;
    utils.utilizeCurrentPosition(
      (position) => registerDakoku(dakokuKb, position.coords.latitude, position.coords.longitude),
      () => registerDakoku(dakokuKb, -9999.0, -9999.0)
    );
  };

  return (
    <>
      <div className="mb-3 flex">
        <div className="mx-1 w-1/2">
          <IconTextButton text={'出勤'} onClick={() => onClick(1)} testId="work-start" />
        </div>
        <div className="mx-1 w-1/2">
          <IconTextButton text={'退勤'} onClick={() => onClick(2)} testId="work-end" />
        </div>
      </div>
      <div>対象日 : {currentDate}</div>
      <div>社員名 : {userInfo.userName}</div>
      <br />
      {listDakoku.length === 0
        ? undefined
        : listDakoku.map((list) => (
            <table key={`${list.dakokuStart}-${list.dakokuEnd}`} className="mb-2 w-full">
              <tbody>
                <DataRow text="現場" value={list.name} colSpan={3} />
                <tr>
                  <td className="w-12 border border-gray-500 bg-gray-200 text-center">出勤</td>
                  <td className="w-12 border border-gray-500">
                    {list.dakokuStart ? DateTime.fromISO(list.dakokuStart).toLocaleString(DateTime.TIME_24_SIMPLE) : ''}
                  </td>
                  <td className="w-12 border border-gray-500 bg-gray-200 text-center">退勤</td>
                  <td className="w-12 border border-gray-500">
                    {list.dakokuEnd ? DateTime.fromISO(list.dakokuEnd).toLocaleString(DateTime.TIME_24_SIMPLE) : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
      <br />
    </>
  );
};

export default Dakoku;
