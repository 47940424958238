import { useEffect, useState } from 'react';
import MapPinRegister from 'src/assets/images/map-pin-register.png';
import MapPin from 'src/assets/images/map-pin.png';
import { fetchUrl } from 'src/constant/constant';
import { Koji } from 'src/data-type/data-type';
import { UpdateKojiLocationReq } from 'src/data-type/request-data-type';
import { ConfirmMessage, HtmlInfoMessage } from 'src/enum/message-enum';
import appFetch from 'src/services/app-fetch';
import utils from 'src/services/utils';

type LocationRegisterProps = {
  koji: Koji | undefined;
};

const LocationRegister = ({ koji }: LocationRegisterProps) => {
  const [isRegistered, setIsRegistered] = useState(false);

  const registerLocation: PositionCallback = async (position) => {
    const { latitude, longitude } = utils.roundDownPositionData(position.coords.latitude, position.coords.longitude);

    // このFunctionが呼ばれるタイミングではkojiはundefinedではないので、明示的にTypeを指定する
    const res = await appFetch.put<UpdateKojiLocationReq>(fetchUrl.updateKojiLocation, {
      parentCode: (koji as Koji).parentCode,
      childCode: (koji as Koji).childCode,
      latitude: latitude,
      longitude: longitude,
    });

    if (res === undefined) return;

    if (res.status === 200) {
      setIsRegistered(true);
    }
  };

  const deleteLocation = async () => {
    const res = await appFetch.put<UpdateKojiLocationReq>(fetchUrl.updateKojiLocation, {
      parentCode: (koji as Koji).parentCode,
      childCode: (koji as Koji).childCode,
      latitude: null,
      longitude: null,
    });

    if (res === undefined) return;

    if (res.status === 200) {
      setIsRegistered(false);
    }
  };

  const onClick = async () => {
    if (koji === undefined) return;

    const isProceeded = window.confirm(
      isRegistered ? ConfirmMessage.RemoveRegisteredLocation : ConfirmMessage.RegisterCurrentLocation
    );

    if (!isProceeded) return;

    if (!isRegistered) {
      utils.utilizeCurrentPosition(registerLocation);
    } else {
      deleteLocation();
    }
  };

  useEffect(() => {
    if (koji === undefined) return;
    // 選択された工事のマップ保持状態とUIのMap保持状態が同じであれば
    // 不要なレンダリングはしない。
    if (Boolean(koji.latitude && koji.longitude) === isRegistered) return;
    setIsRegistered(Boolean(koji.latitude && koji.longitude));
  }, [koji]);

  return (
    <button
      onClick={onClick}
      title={isRegistered ? HtmlInfoMessage.RegisteredAlready : HtmlInfoMessage.LocationRegister}
      className="outline-none"
      data-cy="location-reg"
    >
      {isRegistered ? (
        <img src={MapPinRegister} alt="mapPinRegister" className="mx-1 mt-1.5 h-9 w-9" />
      ) : (
        <img src={MapPin} alt="mapPin" className="mx-1.5 mt-2.5 h-8 w-8" />
      )}
    </button>
  );
};

export default LocationRegister;
