import { useEffect, useMemo, useState } from 'react';
import { fetchUrl } from 'src/constant/constant';
import { Koji } from 'src/data-type/data-type';
import { KojiSearchReq } from 'src/data-type/request-data-type';
import { KojiSearchRes } from 'src/data-type/response-data-type';
import { InfoMessage } from 'src/enum/message-enum';
import { StorageKey } from 'src/enum/string-enum';
import appFetch from 'src/services/app-fetch';
import utils from 'src/services/utils';
import BaseModal from '../atoms/base-modal';
import GuideContent from '../molecules/guide-content';
import IconTextButton from '../molecules/icon-text-button';
import LabelInput from '../molecules/label-input';
import KojiSearchResult from './koji-search-result';
import NeighborSearchSwitch from './neighbor-search-switch';

type KojiGuideProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setKoji: (koji: Koji) => void;
};

const KojiGuide = ({ isOpen, setIsOpen, setKoji }: KojiGuideProps) => {
  const [nameFilter, setNameFilter] = useState('');
  const [parentCodeFilter, setParentCodeFilter] = useState('');
  const [childCodeFilter, setChildCodeFilter] = useState<number | null>(null);
  const [addressFilter, setAddressFilter] = useState('');
  const [searchResult, setSearchResult] = useState<KojiSearchRes[]>([]);
  const [isNeighborSearch, setIsNeighborSearch] = useState(false);

  // Fetch処理が完了したかどうかを持つ
  const [didSearch, setDidSearch] = useState(false);

  const onSearchClick = async () => {
    setDidSearch(false);
    const res = await appFetch.post<KojiSearchReq, KojiSearchRes[]>(fetchUrl.searchKoji, {
      name: nameFilter,
      parentCode: parentCodeFilter,
      childCode: childCodeFilter,
      address: addressFilter,
    });
    setDidSearch(true);

    if (res === undefined) return;

    if (res.status === 200) {
      setSearchResult(res.data);
    }
  };

  const onToggleClick = async () => {
    setIsNeighborSearch(!isNeighborSearch);

    // 切り替えの際は、データ表示部を初期化する
    setSearchResult([]);
    setDidSearch(false);

    // 通常に切り替える際は何もしない
    if (isNeighborSearch) return;

    utils.utilizeCurrentPosition((position) => fetchNearbyKoji(position, undefined, () => setDidSearch(true)));
  };

  const onDataClick = (data: Koji) => {
    setKoji(data);
    setIsOpen(false);

    // 前回地をStorageに保存
    localStorage.setItem(StorageKey.PrevParentCode, data.parentCode);
    localStorage.setItem(StorageKey.PrevChildCode, data.childCode.toString());
  };

  // ガイドを開いたタイミングとユーザーがトグルボタンを"近辺"にしたタイミングでキックされる処理
  // setToggleTrueはガイドを開いた際にデータがあれば、自動でトグルボタンを近辺に変更する
  // setSearchFinishは、ユーザー操作による近辺の現場検索になるので、検索処理が完了したことをStateに保存する
  const fetchNearbyKoji = (position: GeolocationPosition, setToggleTrue?: () => void, setSearchFinish?: () => void) => {
    // 10m 程度の誤差は無視してデータを格納する
    // 少数第５位以下を切り捨てて検索
    const { latitude, longitude } = utils.roundDownPositionData(position.coords.latitude, position.coords.longitude);

    appFetch
      .post<KojiSearchReq, KojiSearchRes[]>(fetchUrl.searchKoji, { latitude: latitude, longitude: longitude })
      .then((res) => {
        if (res === undefined) return;
        if (res.status === 200) {
          setSearchFinish && setSearchFinish();
          if (res.data.length > 0) {
            setSearchResult(res.data);
            setToggleTrue && setToggleTrue();
          }
        }
      });
  };

  useEffect(() => {
    if (isOpen)
      utils.utilizeCurrentPosition(
        (position) => fetchNearbyKoji(position, () => setIsNeighborSearch(true)),
        () => {}
      );
    else {
      // State関連の初期化
      setNameFilter('');
      setParentCodeFilter('');
      setChildCodeFilter(null);
      setAddressFilter('');
      setSearchResult([]);
      setIsNeighborSearch(false);
      setDidSearch(false);
    }
  }, [isOpen]);

  const results = useMemo(() => <KojiSearchResult results={searchResult} onDataClick={onDataClick} />, [searchResult]);

  return (
    <BaseModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <GuideContent isOpen={isOpen} setIsOpen={setIsOpen} headerLabel={'現場検索'}>
        <>
          <div className="mb-4 mr-4 flex">
            <NeighborSearchSwitch
              isNeighBorSearch={isNeighborSearch}
              onClick={onToggleClick}
              testId="neighbor-search"
            />
          </div>
          {isNeighborSearch ? null : (
            <>
              <div className="mr-5">
                <div className="my-2">
                  <LabelInput text="名称" value={nameFilter} onChange={setNameFilter} />
                </div>
                <div className="my-2">
                  <LabelInput text="番号" value={parentCodeFilter} onChange={setParentCodeFilter} />
                </div>
                <div className="my-2">
                  <LabelInput
                    text="枝番"
                    value={childCodeFilter ?? ''}
                    onChange={(value) => {
                      if (value === '') setChildCodeFilter(null);
                      else {
                        const reg = new RegExp('^([0-9]|[1-9][0-9])$');
                        if (reg.test(value)) setChildCodeFilter(Number(value));
                      }
                    }}
                    testId="child-code"
                  />
                </div>
                <div className="my-2">
                  <LabelInput text="住所" value={addressFilter} onChange={setAddressFilter} />
                </div>
              </div>
              <div className="my-4 mx-5">
                <IconTextButton text={'検索'} onClick={onSearchClick} testId="guide-search" />
              </div>
            </>
          )}
          <div className="mx-1">
            {searchResult.length >= 1 ? results : didSearch ? InfoMessage.KojiNotFound : <div />}
          </div>
        </>
      </GuideContent>
    </BaseModal>
  );
};

export default KojiGuide;
