type NeighborSearchSwitchProps = {
  isNeighBorSearch: boolean;
  onClick: () => void;
  testId?: string;
};

const NeighborSearchSwitch = ({ isNeighBorSearch, onClick, testId }: NeighborSearchSwitchProps) => {
  return (
    <>
      <span className="flex-grow" />
      <div
        className={`relative h-[30px] w-[80px] cursor-pointer rounded-[30px] transition duration-500 ${
          isNeighBorSearch ? 'bg-[#00c853] ' : 'bg-[#2c3e50]'
        }`}
        onClick={onClick}
        data-testid={testId}
      >
        <label className={`absolute top-0.5  ${isNeighBorSearch ? 'left-3 translate-x-[-4px]' : 'right-3 text-white'}`}>
          {isNeighBorSearch ? '近辺' : '通常'}
        </label>
        <div
          className={`absolute left-[3px] bottom-[2.6px] h-[25px] w-[25px] rounded-full bg-[#fff] transition duration-500 ${
            isNeighBorSearch && 'translate-x-[49px]'
          }`}
        />
      </div>
    </>
  );
};

export default NeighborSearchSwitch;
