type InputProps = {
  value: string | number | readonly string[] | undefined;
  onChange?: (value: string) => void;
  onClick?: () => void;
  readOnly?: boolean;
  testId?: string;
};

const Input = ({ value, onChange, onClick, readOnly = false, testId }: InputProps) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onClick && e.key === 'Enter') onClick();
  };

  return (
    <div onClick={onClick} onKeyDown={onKeyDown} data-testid={`Input-div-${testId}`} data-cy={`Input-div-${testId}`}>
      <input
        className="h-10 w-full rounded-lg border border-[#aaa] px-1.5 pt-1 pb-1 shadow-[inset_0_1px_4px_rgb(0,0,0,0.2)] read-only:cursor-pointer read-only:bg-gray-300 focus:shadow-gray-800 focus:outline-none"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        readOnly={readOnly}
        data-testid={`Input-input-${testId}`}
        data-cy={`Input-input-${testId}`}
      />
    </div>
  );
};

export default Input;
