import Input from '../atoms/input';
import Label from '../atoms/label';

type LabelInputProps = {
  text: string;
  value: string | number | readonly string[] | undefined;
  onChange?: (value: string) => void;
  onClick?: () => void;
  readOnly?: boolean;
  testId?: string;
};

const LabelInput = ({ text, value, onChange, onClick, readOnly = false, testId }: LabelInputProps) => {
  return (
    <div className="flex">
      <div className="my-auto w-2/12 flex-initial text-center">
        <Label text={`${text}:`} />
      </div>
      <div className="w-10/12">
        <Input value={value} readOnly={readOnly} onChange={onChange} onClick={onClick} testId={testId} />
      </div>
    </div>
  );
};

export default LabelInput;
