import { useEffect, useState } from 'react';
import { fetchUrl } from 'src/constant/constant';
import { UserInfoRes } from 'src/data-type/response-data-type';
import appFetch from './app-fetch';

type UserInfo = {
  tenantId: number | null;
  tenantName: string;
  userId: number | null;
  userName: string;
};

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({ tenantId: null, tenantName: '', userId: null, userName: '' });

  useEffect(() => {
    appFetch.get<UserInfoRes>(fetchUrl.getUserInfo).then((res) => {
      if (res === undefined) return;
      if (res.status === 200)
        setUserInfo({
          tenantId: res.data.tenantId,
          tenantName: res.data.tenantName,
          userId: res.data.userId,
          userName: res.data.userName,
        });
    });
  }, []);

  return userInfo;
};
