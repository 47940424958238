import { ButtonStyle } from 'src/enum/numeric-enum';
import Button from '../atoms/button';

type IconTextButtonProps = {
  text: string;
  onClick?: () => void;
  btnStyle?: ButtonStyle;
  icon?: React.ReactNode;
  testId?: string;
};

const IconTextButton = ({ icon, text, btnStyle, onClick, testId }: IconTextButtonProps) => {
  return (
    <Button onClick={onClick} btnStyle={btnStyle} testId={testId}>
      <div className="flex">
        {icon && (
          <div className="m-auto h-4 w-4 rounded-full bg-black text-white drop-shadow-[0_1px_0_rgba(255,255,255,0.3)]">
            {icon}
          </div>
        )}
        <span className="m-auto font-bold text-white">{text}</span>
      </div>
    </Button>
  );
};

export default IconTextButton;
