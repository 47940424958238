import { Koji } from 'src/data-type/data-type';
import { KojiSearchRes } from 'src/data-type/response-data-type';
import DataRow from '../atoms/data-row';

type KojiSearchResultProps = {
  results: KojiSearchRes[];
  onDataClick: (data: Koji) => void;
};

const KojiSearchResult = ({ results, onDataClick }: KojiSearchResultProps) => {
  return (
    <>
      <div className="mb-2 flex flex-row-reverse">{results.length}件の現場が見つかりました。</div>
      <div className="">
        {results.map((data, index) => (
          <table key={`${data.parentCode}-${data.childCode}`} className="mb-2 w-full">
            <tbody>
              <DataRow
                text="名称"
                value={data.shortName}
                link
                onDataClick={() => onDataClick(data)}
                testId={`koji-link-${index}`}
              />
              <DataRow text="番号" value={`${data.parentCode}-${data.childCode.toString().padStart(2, '0')}`} />
              <DataRow text="住所１" value={data.address1} />
              <DataRow text="住所２" value={data.address2} />
            </tbody>
          </table>
        ))}
      </div>
    </>
  );
};

export default KojiSearchResult;
