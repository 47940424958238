import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { fetchUrl } from 'src/constant/constant';
import { useAuthContext } from 'src/context/context';
import { DestroyAuthReq } from 'src/data-type/request-data-type';
import { ButtonStyle } from 'src/enum/numeric-enum';
import { CookieKey } from 'src/enum/string-enum';
import appFetch from 'src/services/app-fetch';
import TitleBar from '../atoms/title-bar';
import IconTextButton from '../molecules/icon-text-button';

const NavigationBar = () => {
  const { setAuthState } = useAuthContext();

  const onClick = async () => {
    const res = await appFetch.post<DestroyAuthReq>(fetchUrl.destroyAuth, { key: CookieKey.UserAuth });

    if (res === undefined) return;

    if (res.status === 200) {
      setAuthState({ isTenantLogin: true, isUserLogin: false });
    }
  };

  return (
    <div className="relative">
      <div className="absolute top-2 left-2 h-8 w-28">
        <IconTextButton
          icon={<ChevronLeftIcon />}
          text="ログアウト"
          onClick={onClick}
          btnStyle={ButtonStyle.OnTitleBar}
          testId="logout"
        />
      </div>
      <TitleBar text="打刻" />
    </div>
  );
};

export default NavigationBar;
