import Modal from 'react-modal';

type BaseModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
};

const customStyles: Modal.Styles = {
  overlay: {},
  content: {
    position: 'absolute',
    top: '3%',
    left: '3%',
    right: '3%',
    bottom: '3%',
    maxWidth: '500px',
    padding: '0px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const BaseModal = ({ isOpen, setIsOpen, children }: BaseModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(!isOpen)} ariaHideApp={false} style={customStyles}>
      {children}
    </Modal>
  );
};

export default BaseModal;
