export enum InfoMessage {
  RequiredInput = '必須入力です。',
  ExceededMaxLength = '最大桁数を超えています。',
  UnexpectedError = '予期せぬエラーが発生しました。',
  ServerUnexpectedError = 'サーバーで予期せぬエラーが発生しました。',
  KojiNotFound = '該当する現場が見つかりません。',
  TenantNotFound = 'テナントIDかパスワードが不正です。',
  UserNotFound = 'ユーザーIDかパスワードが不正です。',
  SetFilterValues = '取得可能なデータ量を超えています。検索条件を指定して検索をおこなってください。',
  InvalidAuthInfo = '認証情報が不正です。再度ログインをしてください。',
  NotAllowedLocationAccess = '位置情報へのアクセスがブロックされています。位置情報共有を許可してください。',
  DakokuDuplication = '対象の現場は打刻済みです。',
  Message403 = 'Forbidden',
  Message413 = 'Too Large response',
  Message500 = 'Internal server error',
}

export enum HtmlInfoMessage {
  LocationRegister = '位置情報を登録します。',
  RegisteredAlready = '位置情報は登録済みです。',
}

export enum ConfirmMessage {
  RegisterCurrentLocation = '現在座標を、この現場の位置情報として登録しますか？',
  RemoveRegisteredLocation = 'この現場の位置情報を削除しますか？',
}
