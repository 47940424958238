export enum ButtonStyle {
  Standard,
  OnTitleBar,
}

export enum ProcessState {
  None,
  Loading,
  Loaded,
}
