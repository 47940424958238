import { UseFormRegisterReturn } from 'react-hook-form';
import Label from '../atoms/label';
import FormError from '../atoms/react-form/form-error';
import FormInput from '../atoms/react-form/form-input';

export type FormBasedInputProps = {
  text: string;
  type: React.HTMLInputTypeAttribute | undefined;
  formRegister: UseFormRegisterReturn<any>;
  errors: { id: number; value: string | undefined }[];
  placeholder: string;
};

const FormBasedInput = ({ text, type, formRegister, errors, placeholder }: FormBasedInputProps) => {
  return (
    <>
      <div className="block pb-2 min-[450px]:inline-block min-[450px]:w-1/5  min-[450px]:align-top">
        <Label text={text} />
      </div>
      <div className="min-[450px]:inline-block min-[450px]:w-4/5">
        <FormInput type={type} formRegister={formRegister} placeholder={placeholder} />
      </div>
      {errors.map((error) => (
        <FormError key={error.id} value={error.value} />
      ))}
    </>
  );
};

export default FormBasedInput;
