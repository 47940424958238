import { ButtonStyle } from 'src/enum/numeric-enum';

type ButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  btnStyle?: ButtonStyle;
  testId?: string;
};

const Button = ({ onClick, btnStyle, children, testId }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`h-full w-full rounded-2xl text-white 
      ${
        btnStyle === ButtonStyle.OnTitleBar
          ? 'bg-gradient-to-b from-[#444] to-[#2d2d2d] text-sm drop-shadow-[0_1px_0_rgba(255,255,255,0.3)]'
          : 'h-12 border border-[#044062] bg-gradient-to-b from-[#5f9cc5] to-[#396b9e] shadow-[0_1px_4px_rgb(0,0,0,0.3)] focus:shadow-md focus:shadow-blue-300 focus:outline-none'
      }
      `}
      data-testid={testId}
      data-cy={testId}
    >
      {children}
    </button>
  );
};

export default Button;
