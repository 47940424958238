import { useState } from 'react';
import { fetchUrl } from 'src/constant/constant';
import { Koji } from 'src/data-type/data-type';
import { KojiSearchReq } from 'src/data-type/request-data-type';
import { KojiSearchRes } from 'src/data-type/response-data-type';
import { StorageKey } from 'src/enum/string-enum';
import appFetch from 'src/services/app-fetch';
import { useEffectOnce } from 'usehooks-ts';
import Main from '../templates/main';

const Home = () => {
  const [koji, setKoji] = useState<Koji | undefined>();

  // 前回値を復旧
  useEffectOnce(() => {
    // ユーザー操作による値の変更も考慮されるので、不正な場合はStorage値のクリアを行う
    const prevP = localStorage.getItem(StorageKey.PrevParentCode);
    const prevC = localStorage.getItem(StorageKey.PrevChildCode);

    if (prevP === null || prevC === null) {
      localStorage.removeItem(StorageKey.PrevParentCode);
      localStorage.removeItem(StorageKey.PrevChildCode);
      return;
    }

    appFetch
      .post<KojiSearchReq, KojiSearchRes[]>(fetchUrl.searchKoji, {
        parentCode: prevP,
        childCode: Number(prevC),
        isDistinct: true,
      })
      .then((res) => {
        if (res === undefined) throw new Error();

        if (res.status === 200 && res.data.length > 0) {
          const d = res.data[0];
          setKoji({
            parentCode: d.parentCode,
            childCode: d.childCode,
            shortName: d.shortName,
            latitude: d.latitude,
            longitude: d.longitude,
          });
        } else throw new Error();
      })
      .catch(() => {
        localStorage.removeItem(StorageKey.PrevParentCode);
        localStorage.removeItem(StorageKey.PrevChildCode);
        console.log('Invalid previous stored value');
      });
  });

  return <Main koji={koji} setKoji={setKoji} />;
};

export default Home;
