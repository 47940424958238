import Home from './components/pages/home';
import TenantLogin from './components/pages/tenant-login';
import UserLogin from './components/pages/user-login';
import { useAuthContext } from './context/context';

const App = () => {
  const { authState } = useAuthContext();

  // ログイン状態を確認し、未認証であれば、認証画面を表示
  if (!authState.isTenantLogin) return <TenantLogin />;
  if (!authState.isUserLogin) return <UserLogin />;

  return <Home />;
};

export default App;
