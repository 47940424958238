import { useState } from 'react';
import { Koji } from 'src/data-type/data-type';
import LabelInput from '../molecules/label-input';
import KojiGuide from './koji-guide';

type SearchKojiProps = {
  name: string;
  setKoji: (koji: Koji) => void;
};

const SearchKoji = ({ name, setKoji }: SearchKojiProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="h-full w-full">
      <LabelInput text="現場" value={name} onClick={() => setIsOpen(!isOpen)} readOnly testId="koji-input-click" />
      <KojiGuide isOpen={isOpen} setIsOpen={setIsOpen} setKoji={setKoji} />
    </div>
  );
};

export default SearchKoji;
