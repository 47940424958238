import { Koji } from 'src/data-type/data-type';
import Dakoku from '../organisms/dakoku';
import LocationRegister from '../organisms/location-register';
import NavigationBar from '../organisms/navigation-bar';
import SearchKoji from '../organisms/search-koji';
import TimeDisplay from '../organisms/time-display';

type MainProps = {
  koji: Koji | undefined;
  setKoji: (koji: Koji) => void;
};

const Main = ({ koji, setKoji }: MainProps) => {
  return (
    <div className="h-screen bg-[#f3f2f3]">
      <NavigationBar />
      <div className="px-4 pt-4">
        <TimeDisplay />
        <div className="my-1 flex">
          <div className="w-full py-2">
            <SearchKoji name={koji?.shortName ?? ''} setKoji={setKoji} />
          </div>
          <div className="my-1">
            <LocationRegister koji={koji} />
          </div>
        </div>
        <div className="mx-0.5 w-4/12">
          <div className="flex">
            <div className="mx-auto w-12"></div>
          </div>
        </div>
        <div className="mt-5">
          <Dakoku koji={koji} />
        </div>
      </div>
    </div>
  );
};

export default Main;
