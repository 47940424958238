import { Factory } from 'miragejs';
import { KojiTable } from './mock-data-type';

const kojiTable = Factory.extend<KojiTable>({
  name(i) {
    return `工事名称${i + 1}`;
  },
  shortName(i) {
    return `工事略称${i + 1}`;
  },
  parentCode(i) {
    return (i + 1).toString().padStart(8, '0');
  },
  childCode(i) {
    return i % 2;
  },
  address1(i) {
    return `テスト住所1-${i}`;
  },
  address2(i) {
    return `テスト住所2-${i}`;
  },
  latitude(i) {
    if (i % 2 === 1) return 1;
  },
  longitude(i) {
    if (i % 2 === 1) return 1;
  },
});

export const mockFactories = {
  kojiTable,
};
