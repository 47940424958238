import React from 'react';
import { createRoot } from 'react-dom/client';
import 'src/assets/styles/index.css';
import App from './App';
import AuthProvider from './components/providers/auth-provider';
import CspHeader from './csp-header';
import { makeServer } from './mirage/mock-server';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <CspHeader />
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === 'development') {
  // Backend Mock Serverを起動
  makeServer();
}

if (process.env.NODE_ENV === 'production') {
  // ページ読み込みなどの統計情報を表示
  reportWebVitals(console.log);

  // Deploy環境がUpdateされているかを確認するためのログ
  console.log('Commit Hash', process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION);
}
