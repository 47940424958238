import { SubmitHandler, useForm } from 'react-hook-form';
import { InfoMessage } from 'src/enum/message-enum';
import FormBasedInput from '../molecules/form-based-input';
import IconTextButton from '../molecules/icon-text-button';

export type LoginFormProps = {
  idText: string;
  passText: string;
  onSubmit: SubmitHandler<FormInputs>;
  buttonText: string;
};

export type FormInputs = {
  id: number;
  password: string;
};

const LoginForm = ({ idText, passText, onSubmit, buttonText }: LoginFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>();

  const wrappedSubmit = async (data: FormInputs) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(wrappedSubmit)}>
      <div className="mt-5 block">
        <FormBasedInput
          placeholder={idText}
          text={idText}
          type="number"
          formRegister={{
            ...register('id', {
              required: true,
              maxLength: 50,
            }),
          }}
          errors={[
            { id: 1, value: errors.id?.type === 'required' ? InfoMessage.RequiredInput : undefined },
            { id: 2, value: errors.id?.type === 'maxLength' ? InfoMessage.ExceededMaxLength : undefined },
          ]}
        />
      </div>
      <div className="mt-5 block">
        <FormBasedInput
          placeholder={passText}
          text={passText}
          type="password"
          formRegister={{
            ...register('password', {
              required: true,
              maxLength: 20,
            }),
          }}
          errors={[
            { id: 1, value: errors.password?.type === 'required' ? InfoMessage.RequiredInput : undefined },
            { id: 2, value: errors.password?.type === 'maxLength' ? InfoMessage.ExceededMaxLength : undefined },
          ]}
        />
      </div>
      <div className="mt-6 mb-2">
        <IconTextButton text={buttonText} />
      </div>
    </form>
  );
};

export default LoginForm;
