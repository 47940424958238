import { InfoMessage } from 'src/enum/message-enum';

const headers = { 'X-Api-Key': 'web-kintai-api' };

const get = async <T>(url: string): Promise<{ status: number; data: T } | undefined> => {
  return await fetcher(url);
};

const post = async <T1, T2 = null>(url: string, body: T1): Promise<{ status: number; data: T2 } | undefined> => {
  return await fetcher(url, { method: 'POST', body: JSON.stringify(body) });
};

const put = async <T1, T2 = null>(url: string, body: T1): Promise<{ status: number; data: T2 } | undefined> => {
  return await fetcher(url, { method: 'PUT', body: JSON.stringify(body) });
};

const fetcher = async (url: string, options?: RequestInit) => {
  try {
    const res = await fetch(url, { ...options, ...{ headers: headers, credentials: 'include' } });
    if (res.status === 403) {
      // 認可エラーの場合のみこの403が返ってくる
      // リロードを強制的に行い、認可チェックを走らせる
      console.log(InfoMessage.Message403);
      window.alert(InfoMessage.InvalidAuthInfo);

      window.location.reload();
      return;
    } else if (res.status === 413) {
      console.log(InfoMessage.Message413);
      console.log(await res.json());
      window.alert(InfoMessage.SetFilterValues);
      return;
    } else if (res.status === 500) {
      console.log(InfoMessage.Message500);
      console.log(await res.json());
      window.alert(InfoMessage.ServerUnexpectedError);
      return;
    }
    return { status: res.status, data: await res.json() };
  } catch (err) {
    console.log(err);
    console.log(InfoMessage.UnexpectedError);
    return;
  }
};

const appFetch = { get, post, put };

export default appFetch;
