type DataRowProps = {
  text: string;
  value?: string;
  link?: boolean;
  onDataClick?: () => void;
  testId?: string;
  colSpan?: number;
};

const DataRow = ({ text, value, link = false, onDataClick, testId, colSpan }: DataRowProps) => {
  return (
    <tr>
      <td className="w-3/12 border border-gray-500 bg-gray-200 text-center">{text}</td>
      <td className="w-9/12 break-all border border-gray-500" colSpan={colSpan}>
        {link ? (
          <button
            onClick={onDataClick}
            className="cursor-pointer text-sky-400 underline outline-sky-300"
            data-cy={testId}
          >
            {value}
          </button>
        ) : (
          value
        )}
      </td>
    </tr>
  );
};

export default DataRow;
