import { UseFormRegisterReturn } from 'react-hook-form';

type FormInputProps = {
  type: React.HTMLInputTypeAttribute | undefined;
  formRegister: UseFormRegisterReturn<any>;
  placeholder: string;
};

const FormInput = ({ type, formRegister, placeholder }: FormInputProps) => {
  return (
    <input
      className="w-full rounded-lg border border-[#aaa] bg-[#f9f9f9] bg-none p-[0.4rem] 
      leading-normal shadow-[inset_0_1px_4px_rgb(0,0,0,0.2)] outline-0 focus:shadow-[0_0_12px_#387bbe]"
      type={type}
      data-testid={formRegister.name}
      {...formRegister}
      placeholder={placeholder}
    />
  );
};

export default FormInput;
