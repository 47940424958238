import { ButtonStyle } from 'src/enum/numeric-enum';
import Button from '../atoms/button';

type IconButtonProps = {
  onClick: () => void;
  icon?: React.ReactNode;
  btnStyle?: ButtonStyle;
};

const IconButton = ({ icon, onClick, btnStyle }: IconButtonProps) => {
  return (
    <Button onClick={onClick} btnStyle={btnStyle}>
      <>{icon && <div className="m-auto w-6">{icon}</div>}</>
    </Button>
  );
};

export default IconButton;
