const LoginHeader = ({ children, isTenant = false }: { children: React.ReactNode; isTenant?: boolean }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#f9f9f9] to-[#eee]">
      <div
        className={`bg-gradient-to-b ${isTenant ? 'from-gray-300' : 'from-blue-300'} to-white bg-top bg-repeat-x p-4`}
      >
        <div className="h-24" />
        <div className="text-center text-2xl font-bold text-[#333] drop-shadow-[0_1px_0_#fff]">
          <h2>どっと原価NEO</h2>
          <h2>WEB勤怠打刻</h2>
          {isTenant ? <h2>テナント認証</h2> : <h2>ユーザーログイン</h2>}
        </div>
        {children}
      </div>
      <div className="border border-[#b3b3b3]" />
    </div>
  );
};

export default LoginHeader;
