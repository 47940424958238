import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon';
import { ButtonStyle } from 'src/enum/numeric-enum';
import TitleBar from '../atoms/title-bar';
import IconButton from './icon-button';

type GuideContentProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  headerLabel: string;
  children: React.ReactNode;
};

const GuideContent = ({ isOpen, setIsOpen, headerLabel, children }: GuideContentProps) => {
  return (
    <>
      <div className="relative">
        <div className="absolute top-2 left-2 h-8 w-8">
          <IconButton icon={<ChevronLeftIcon />} onClick={() => setIsOpen(!isOpen)} btnStyle={ButtonStyle.OnTitleBar} />
        </div>
        <TitleBar text={headerLabel} />
      </div>
      <div className="py-4 px-2">{children}</div>
    </>
  );
};

export default GuideContent;
