import { InfoMessage } from 'src/enum/message-enum';

const handleObtainingLocationError: PositionErrorCallback = (error) => {
  console.log(error);
  window.alert(InfoMessage.NotAllowedLocationAccess);
};

const utilizeCurrentPosition = (successCallback: PositionCallback, errorCallBack?: PositionErrorCallback) => {
  navigator.geolocation.getCurrentPosition(
    successCallback,
    errorCallBack ? errorCallBack : handleObtainingLocationError
  );
};

const roundDownPositionData = (latitude: number, longitude: number) => {
  return { latitude: Math.floor(latitude * 1000) / 1000, longitude: Math.floor(longitude * 1000) / 1000 };
};

const utils = { utilizeCurrentPosition, roundDownPositionData };

export default utils;
