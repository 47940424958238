const API_END_POINT = process.env.REACT_APP_API_GW_END_POINT;

export const fetchUrl = {
  authTenant: `${API_END_POINT}/auth-tenant`,
  authUser: `${API_END_POINT}/auth-user`,
  checkAuthState: `${API_END_POINT}/check-auth-state`,
  destroyAuth: `${API_END_POINT}/destroy-auth`,
  updateKojiLocation: `${API_END_POINT}/update-koji-location`,
  searchKoji: `${API_END_POINT}/search-koji`,
  getUserInfo: `${API_END_POINT}/get-user-info`,
  listDakoku: `${API_END_POINT}/list-dakoku`,
  registerDakoku: `${API_END_POINT}/register-dakoku`,
};
