type TitleBarProps = {
  text: string;
};

const TitleBar = ({ text }: TitleBarProps) => {
  return (
    <div className="bg-gradient-to-b from-[#3c3c3c] to-[#111] pt-3 pb-3 text-center text-white">
      <h1 className="font-bold">{text}</h1>
    </div>
  );
};

export default TitleBar;
