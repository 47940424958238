import { useState } from 'react';
import { fetchUrl } from 'src/constant/constant';
import type { AuthState } from 'src/context/context';
import { AuthContext } from 'src/context/context';
import { DestroyAuthReq } from 'src/data-type/request-data-type';
import { CookieKey } from 'src/enum/string-enum';
import appFetch from 'src/services/app-fetch';
import { useEffectOnce } from 'usehooks-ts';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState | undefined>(undefined);

  // 認証状態を確認する
  const checkAuthState = () => {
    appFetch.get<AuthState>(fetchUrl.checkAuthState).then((res) => {
      if (res === undefined) return;

      if (res.status === 200) {
        setAuthState({ isTenantLogin: true, isUserLogin: true });
      } else if (res.status === 401) {
        // 認可情報の不備は最低でもユーザーログイン画面には引き戻す。テナント情報にも不備がある場合はテナントログインまで戻す
        // 不正な認可情報もクリアしておく。
        appFetch.post<DestroyAuthReq>(fetchUrl.destroyAuth, { key: CookieKey.UserAuth });
        if (!res.data.isTenantLogin) appFetch.post<DestroyAuthReq>(fetchUrl.destroyAuth, { key: CookieKey.TenantAuth });
        setAuthState({ isTenantLogin: res.data.isTenantLogin, isUserLogin: false });
      }
    });
  };

  // タブがアクティブになった場合、確認・延長 or 破棄する
  // Reloadされた場合、確認・延長 or 破棄する
  useEffectOnce(() => {
    const prevEv = window.onfocus;
    window.onfocus = () => checkAuthState();
    return () => {
      window.onfocus = prevEv;
    };
  });

  // Reloadされた場合、確認・延長 or 破棄する
  useEffectOnce(() => {
    checkAuthState();
  });

  if (authState === undefined) return <div>Loading...</div>;

  return <AuthContext.Provider value={{ authState, setAuthState }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
