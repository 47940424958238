import { Helmet, HelmetProvider } from 'react-helmet-async';
import { fetchUrl } from 'src/constant/constant';

const CspHeader = () => {
  const apiSource = Object.values(fetchUrl).join(' ');

  let csp = '';
  csp += `default-src 'none';`;
  csp += `script-src 'self' ${process.env.NODE_ENV === 'development' ? "'unsafe-eval'" : ''};`;
  csp += `connect-src 'self' ${process.env.NODE_ENV === 'development' ? `${apiSource}` : ''} ws:;`;
  csp += `img-src 'self' data:;`;
  csp += `style-src 'self' 'unsafe-inline';`;
  csp += `manifest-src 'self';`;

  return (
    <HelmetProvider>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content={csp} />
      </Helmet>
    </HelmetProvider>
  );
};

export default CspHeader;
