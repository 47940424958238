import { SubmitHandler } from 'react-hook-form';
import { fetchUrl } from 'src/constant/constant';
import { useAuthContext } from 'src/context/context';
import { TenantLoginReq } from 'src/data-type/request-data-type';
import { TenantLoginRes } from 'src/data-type/response-data-type';
import { InfoMessage } from 'src/enum/message-enum';
import appFetch from 'src/services/app-fetch';
import { FormInputs } from '../organisms/login-form';
import Login from '../templates/login';

const TenantLogin = () => {
  const { setAuthState } = useAuthContext();

  const onTenantSubmit: SubmitHandler<FormInputs> = async (data) => {
    const res = await appFetch.post<TenantLoginReq, TenantLoginRes>(fetchUrl.authTenant, {
      tenantId: Number(data.id),
      password: data.password,
    });

    if (res === undefined) return;

    if (res.status === 200) {
      if (res.data.isTenantFound) {
        setAuthState({ isTenantLogin: true, isUserLogin: false });
      } else {
        window.alert(InfoMessage.TenantNotFound);
      }
    }
  };

  return (
    <Login idText="テナントID" passText="テナントパスワード" onSubmit={onTenantSubmit} buttonText="次へ" isTenant />
  );
};

export default TenantLogin;
